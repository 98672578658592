@use 'common' as common;
@use './global-override-styles.scss';
@import '@navikt/ds-css/dist/index.css';
@import '@navikt/nav-office-reception-info/dist/style.css';

@media (prefers-reduced-motion: no-preference) {
    html {
        scroll-behavior: smooth;
    }
}

#__next,
.decorator-utils-container {
    background-color: common.$a-bg-subtle;
}

.decorator-utils-container {
    min-height: 4rem;
}

#decorator-header {
    min-height: 72px;

    @media #{common.$mq-screen-tablet-and-desktop} {
        min-height: 80px;
    }
}

@media print {
    .decorator-wrapper {
        display: none;
    }
}

// Override aksel styling
.navds-heading {
    word-break: break-word;
}
.navds-ingress {
    line-height: 1.5;
}

figure {
    margin-left: 0;
    margin-right: 0;
}

table {
    .spacer-row {
        height: 1rem;
        background-color: transparent;
    }
}

// Catch-all to prevent unnecessary margin-bottom whitespace
.layout:last-child,
.part:last-child {
    margin-bottom: 0;
    & > :last-child {
        margin-bottom: 0;
    }
}

// Prevent the "Customize page" dialog from appearing in the editor
// on content types which we never want to have customized
body.legacyContentType {
    .item-view-context-menu,
    .xp-page-editor-shader {
        display: none !important;
    }
}
